import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  variant: PropTypes.oneOf(['sm', 'md', 'ctr']),
  className: PropTypes.string,
};
const defaultProps = {
  variant: 'md',
  className: null,
};

function ErrorBoundaryMessage({ variant, className }) {
  const variants = {
    sm: {
      heading: 'h5',
      text: 'small',
    },
    md: {
      heading: 'h4',
    },
    ctr: {
      align: 'text-center',
    },
  };
  const variantClassNames = variants[variant];
  const handleRefreshClick = () => window.location.reload();

  return (
    <div className={classNames('error-boundary', variantClassNames.align, className)}>
      <h3 className={classNames('text-danger', variantClassNames.heading)}>
        <em className="icon icon-warning-empty" /> Something went wrong
      </h3>
      <div className={classNames('space-2', variantClassNames.text)}>
        Unfortunately, a technical issue occurred. Please{' '}
        <button type="button" className="btn-chromeless btn-link" onClick={handleRefreshClick}>
          refresh the page
        </button>{' '}
        and try again or contact us.
      </div>
    </div>
  );
}

ErrorBoundaryMessage.propTypes = propTypes;
ErrorBoundaryMessage.defaultProps = defaultProps;

export default ErrorBoundaryMessage;
