import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import ErrorBoundaryMessage from '@/storychief/components/ErrorBoundaryMessage';

const propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['sm', 'md', 'ctr']),
  className: PropTypes.string,
};
const defaultProps = {
  variant: 'md',
  className: null,
};

function ErrorBoundary({ children, variant, className }) {
  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorBoundaryMessage variant={variant} className={className} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

ErrorBoundary.propTypes = propTypes;
ErrorBoundary.defaultProps = defaultProps;

export default ErrorBoundary;
